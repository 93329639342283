import React, { useState } from 'react';
import styled from '@emotion/styled';
import BasicAccordionCard from '@sentry/static-shared/components/molecules/AccordionCard';
import { mqMin } from '../../utils/css';

const FaqAccordion = ({ faqs, mode, multiOpen, multiColumn = false }) => {
  const [selectedCollapses, setSelectedCollapses] = useState([!multiOpen ? faqs[0]?.slug : '']);

  const updateCollapse = (value) => {
    if (multiOpen) {
      const updated = selectedCollapses?.includes(value)
        ? selectedCollapses?.filter(key => key !== value)
        : [...(new Set([...selectedCollapses, value]))]

        setSelectedCollapses(updated);
    } else {
      setSelectedCollapses([value])
    }
  };

  const mapFaqData = ({ question, mdx, answer, slug }) => ({
    title: question,
    key: slug,
    id: slug,
    answer: mdx ?? answer
  });

  const getSelectedCollapse = ({ id }) => selectedCollapses?.filter((key) => id === key)?.pop();

  const faqMiddleIndex = Math.floor(faqs.length / 2)

  const faqFirstCol = faqs.slice(0, faqMiddleIndex);
  const faqSecondCol = faqs.slice(faqMiddleIndex, faqs.length);  

  return multiColumn ? ( 
    <StyledColumnGroupWrapper>
      <AccordionGroup>
        {
          faqFirstCol
          .map(mapFaqData)
          .map(data => <BasicAccordionCard 
            data={data}
            mode={mode}
            multiColumn
          />)
        }
      </AccordionGroup>
      <AccordionGroup>
        {
          faqSecondCol
          .map(mapFaqData)
          .map(data => <BasicAccordionCard 
            data={data}
            mode={mode}
            multiColumn
          />)
        }
      </AccordionGroup>
    </StyledColumnGroupWrapper>
    )
    : <AccordionGroup>
        {
          faqs
          .map(mapFaqData)
          .map(data => <BasicAccordionCard 
            data={data}
            mode={mode}
          />)
        }
      </AccordionGroup>
};

const AccordionGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 0.5rem;
  height: 100%;
`;

const StyledColumnGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: space-between;

  ${mqMin('lg')} {
    flex-wrap: nowrap;
    gap: 2rem;

    > div {
      flex: 1 0 50%;
    }
  }
}
`;

export default FaqAccordion;